import React from "react";
import styles from './MainHeader.module.scss';
import MainNavigation from "./MainNavigation";
import logo from '../../../assets/images/logoAfs.svg';
import logoBlue from '../../../assets/images/logoAfsBlue.svg';
import bg from '../../../assets/backgrounds/bgMainHeader.png';
import HeaderProfile from "./HeaderProfile/HeaderProfile";
import {NavLink} from "react-router-dom";
import User from "../Main/User/User";
import MainMenu from "../Main/MainMenu/MainMenu";
import {useLocation} from 'react-router-dom';
import event from '../../../assets/icons/event.svg';
import MobileTitle from "./MobileTitle";
import {useAppSelector} from "../../../hooks/redux";
import {getIsDarkTheme} from "../../../store/selectors";

const MainHeader = () => {
    const {pathname} = useLocation();
    const isDark = useAppSelector(getIsDarkTheme);

    return (
        <>
            {window.innerWidth < 1024 && pathname.substring(1, 9) !== 'settings' || window.innerWidth >= 1024 ? (
                <div className={`${styles.headerWrapper} homeHeader`}
                     style={pathname.substring(1, 9) !== 'settings' && pathname !== '/notifications' && pathname !== '/spot' ? {backgroundImage: `url(${bg})`} : null}>
                    <div className={styles.header}>
                        <div className={window.innerWidth > 1024 ? styles.header__menuBox : styles.header__menuBoxMob}>
                            {window.innerWidth < 1024 && (
                                <HeaderProfile hide/>
                            )}
                            <NavLink to={'/'}>
                                <img src={pathname ==='/spot' && !isDark ? logoBlue : logo} alt=""/>
                            </NavLink>
                            {window.innerWidth < 1024 && (
                                <NavLink to={'/notifications'}>
                                    <img src={event} alt=""/>
                                </NavLink>
                            )}
                            {window.innerWidth > 1024 && (
                                <MainNavigation/>
                            )}
                        </div>
                        {window.innerWidth > 1024 && (
                            <HeaderProfile/>
                        )}
                    </div>
                    <div className={styles.subHeader}>
                        {pathname === '/' && (
                            <User/>
                        )}
                        {(pathname.substring(1, 9) !== 'settings' && pathname !== '/spot' && pathname !== '/notifications') && window.innerWidth > 743 ? <MainMenu/>
                            : (pathname.substring(1, 9) !== 'settings' && pathname !== '/swap' && pathname !== '/notifications' && pathname !== '/staking') && window.innerWidth < 743 && pathname !== '/' ?
                                <MainMenu /> : null
                        }
                    </div>
                    {window.innerWidth < 743 && (
                        <MobileTitle />
                    )}
                </div>
            ) : null}

            {pathname === '/' && window.innerWidth < 743 && (
                <div className={styles.mobileNavigation}>
                    <MainMenu />
                </div>
            )}
        </>
    )
}

export default MainHeader;