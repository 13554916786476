import React from "react";
import styles from './MobileChooser.module.scss';

const MobileChooser = ({isLeft, setIsLeft, leftText, rightText}) => {
    return (
        <div className={styles.mobileChooser}>
            <div className={styles.chooser}>
                <input type="radio" name='sectionChooser' checked={isLeft} onChange={() => setIsLeft(true)} />
                <p>{leftText}</p>
            </div>
            <div className={styles.chooser}>
                <input type="radio" name='sectionChooser' checked={!isLeft} onChange={() => setIsLeft(false)} />
                <p>{rightText}</p>
            </div>
        </div>
    )
}

export default MobileChooser