import React, {useState} from "react";
import styles from './OrderBookList.module.scss';
import arrow from '../../../../../../../assets/icons/spotArrow.svg';


const BookMiddle = () => {
    const [isUp, setIsUp] = useState(true);

    return (
        <div className={styles.bookMiddle}>
            <p className={isUp ? styles.bookMiddle__up : styles.bookMiddle__down}>
                <img src={arrow} alt=""/>
                <span>70,762.18</span>
            </p>
            <span className={styles.bookMiddle__usdt}>≈ 70,762.18 USD</span>
        </div>
    )
}

export default BookMiddle;