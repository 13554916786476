import React, {useEffect, useState} from "react";
import styles from './FieldSelect.module.scss';
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import './FieldSelect.scss'
import {useAppSelector} from "../../../hooks/redux";
import {getIsDarkTheme, getPictureSrc} from "../../../store/selectors";

const FieldSelect = ({label, placeholder, selectList, value, setValue, amount, setAmount, from = false, setVersusField, price, fromLabel, amountFrom}) => {
    const isDark = useAppSelector(getIsDarkTheme);
    const pictureSrc = getPictureSrc;
    const [currentToToken, setCurrentToToken] = useState('USDT')


    useEffect(() => {

        if (!from && price && value && value.label !== currentToToken) {
            setCurrentToToken(value.label);
            if (value && amountFrom !== '' && fromLabel === 'USDT') {
                setAmount(amountFrom / price);
            } else if (value && amountFrom !== '' && fromLabel !== 'USDT') {
                setAmount(amountFrom * price);
            } else if (amountFrom === '') {
                setAmount('');
            }
        }

    }, [price])

    const handleChange = (val) => {
        setValue(val);
    };

    const handleInputChange = (val) => {
        setAmount(val)
        if (from && price) {
            if (value && val !== '' && fromLabel === 'USDT') {
                setVersusField(val / price);
            } else if (value && val !== '' && fromLabel !== 'USDT') {
                setVersusField(val * price);
            } else if (val === '') {
                setVersusField('');
            }
        }
        if (!from && price) {
            if (value && val !== '' && fromLabel === 'USDT') {
                setVersusField(val * price);
            } else if (value && val !== '' && fromLabel !== 'USDT') {
                setVersusField(val / price);
            } else if (val === '') {
                setVersusField('');
            }
        }
    }

    return (
        <div className={isDark ? `${styles.fieldSelect} fieldSelect` : `${styles.fieldSelectLight} fieldSelect fieldSelectLight`}>
            <div className={styles.fieldBox}>
                <label htmlFor="selectInput"><span>{label}</span></label>
                <input className={`${styles.field}`} name='selectInput' type="phone" placeholder={placeholder}
                       value={amount} onChange={(e) => handleInputChange(e.target.value)}/>
            </div>
            <div className={`${styles.select} graySelect`}>
                <Box sx={{minWidth: 140}}>
                    <FormControl fullWidth>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={value.label}

                            sx={{
                                borderRadius: '8px',
                                color: '#fff',
                                '.MuiSvgIcon-root ': {
                                    fill: "white !important",
                                }
                            }}
                        >
                            {selectList && selectList.length > 0 && (
                                selectList.map((item, index) => (
                                    <MenuItem onClick={() => handleChange(item)} key={index} value={item.label}>
                                        <p className={styles.option}><img src={`${pictureSrc}${item.iconPath}`} alt=""/><span>{item.label}</span>
                                        </p>
                                    </MenuItem>
                                ))
                            )}
                        </Select>
                    </FormControl>
                </Box>
            </div>
        </div>
    )
}

export default FieldSelect;