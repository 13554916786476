import React, {useEffect, useState} from "react";
import styles from './SpotFooter.module.scss';
import LockFooterContent from "./LockFooterContent";
import SpotOrdersList from "./SpotOrdersList/SpotOrdersList";
import SpotFooterTabs from "./SpotFooterTabs";
import {getClosedOrders, getOpenOrders} from "../../../../../api/api";
import {Preloader} from "../../../../Common/Preloader/Preloader";
import {useAppDispatch, useAppSelector} from "../../../../../hooks/redux";
import {setOrderContent, setOrderList} from "../../../../../store/main-slice";
import {getOrderList} from "../../../../../store/selectors";
import SpotMobOrdersList from "./SpotOrdersList/SpotMobOrdersList";

const SpotFooter = ({isAuth, text}) => {
    const dispatch = useAppDispatch();
    const [selectedContent, setSelectedContent] = useState('currentOrders');
    const list = useAppSelector(getOrderList)
    const [isLoading, setIsLoading] = useState(false);
    const [orderCounter, setOrderCounter] = useState(0)

    const onChangeList = (value) => {
        setSelectedContent(value);
        dispatch(setOrderContent(value))
        setIsLoading(true);

        if (value === 'currentOrders') {
            getOpenOrders().then((result) => {
                dispatch(setOrderList(result));
                setIsLoading(false);
                setOrderCounter(result.length)
            })
        } else if (value === 'orderHistory') {
            getClosedOrders().then((result) => {
                dispatch(setOrderList(result));
                setIsLoading(false);
            })
        } else {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        setIsLoading(true);
        getOpenOrders().then((result) => {
            if (result) {
                dispatch(setOrderList(result));
                setOrderCounter(result.length)
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
        })
    }, [])

    return (
        <div className={`${styles.spotFooter} spotBg spotFooter`}>
            <SpotFooterTabs selectedContent={selectedContent} setSelectedContent={onChangeList} text={text}
                            orderCounter={orderCounter}/>
            <div className={styles.content}>
                {window.innerWidth >= 743 && (
                    <div className={isAuth ? styles.content__header : styles.content__headerHide}>
                        <p>{text.date}</p>
                        <p>{text.pair}</p>
                        <p>{text.type}</p>
                        <p>{text.side}</p>
                        <p>{text.price}</p>
                        <p>{text.filled}</p>
                        <p>{text.orderAmount}</p>
                    </div>
                )}
                {!isAuth && (
                    <LockFooterContent/>
                )}
                {isAuth && !isLoading && list && list.length > 0 && (
                    <>
                        {window.innerWidth >= 743
                            ? <SpotOrdersList list={list}/>
                            : <SpotMobOrdersList list={list}/>
                        }
                    </>

                )}
                {isLoading && (
                    <div className='loadingLocal' style={{background: 'transparent'}}>
                        <Preloader/>
                    </div>
                )
                }
            </div>
        </div>
    )
}

export default SpotFooter;