import React, {useEffect} from "react";
import styles from './Event.module.scss';
import close from '../../../assets/icons/close.svg';
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {getEvent} from "../../../store/selectors";
import {setEvent} from "../../../store/account-slice";

const Event = () => {
    const dispatch = useAppDispatch()
    const event = useAppSelector(getEvent);

    useEffect(() => {
        if (event) {
            setTimeout(() => {
                dispatch(setEvent(null))
            }, 5000)
        }
    }, [event])

    return (
        <div className={event ? styles.event : styles.eventHide}>
            <p>{event ? event : ''}</p>
            <button className={styles.event__close} onClick={() => dispatch(setEvent(null))}>
                <img src={close} alt=""/>
            </button>
        </div>
    )
}

export default Event;