import React from "react";
import styles from './SubNotification.module.scss';
import notification from "../../../assets/icons/notification.svg";
import notificationDark from "../../../assets/icons/notificationGray.svg";
import {useAppSelector} from "../../../hooks/redux";
import {getIsDarkTheme} from "../../../store/selectors";


const SubNotification = ({text}) => {
    const isDarkTheme = useAppSelector(getIsDarkTheme);

    return (
        <div className={styles.subNotification}>
            <img src={isDarkTheme ? notification : notificationDark} alt=""/>
            <span>{text}</span>
        </div>
    )
}

export default SubNotification;