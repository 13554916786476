import React from "react";
import styles from './SpotOrdersList.module.scss';
import {useAppSelector} from "../../../../../../hooks/redux";
import {getIsDarkTheme} from "../../../../../../store/selectors";


const SpotMobOrdersList = ({list}) => {
    const isDark = useAppSelector(getIsDarkTheme);

    return (
        <div className={styles.spotOrdersList}>
            {list && (
                list.map((item, index) => (
                    <div key={index} className={styles.mobList} style={isDark ? {backgroundColor: '#0A0C24'} : {backgroundColor: '#EDEDF0'}}>
                        <div className={styles.mobHeader}>
                            <p>{item.pair}</p>
                            <span>{item.orderTime}</span>
                        </div>
                        <div className={styles.mobList__table}>
                            <div className={styles.mobList__item}>
                                <p>Side</p>
                                <p style={item.direction === 'SELL' ? {color: '#FF5959'} : {color: '#80FC34'}}>{item.direction}</p>
                            </div>
                            <div className={styles.mobList__item}>
                                <p>Price</p>
                                <span>{item.price}</span>
                            </div>
                            <div className={styles.mobList__item}>
                                <p>Type</p>
                                <span>{item.type}</span>
                            </div>
                            <div className={styles.mobList__item}>
                                <p>Price</p>
                                <span>{item.price}</span>
                            </div>
                            <div className={styles.mobList__item}>
                                <p>Filled</p>
                                <span>{item.filled}</span>
                            </div>
                            <div className={styles.mobList__item}>
                                <p>Order amount</p>
                                <span>{item.value}</span>
                            </div>
                        </div>
                    </div>
                ))
            )}
        </div>
    )
}

export default SpotMobOrdersList;