import React from "react";
import styles from './SelectNetwork.module.scss';
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import '../TokenChanger/TokenChanger.scss';
import {useAppSelector} from "../../../hooks/redux";
import {getIsDarkTheme} from "../../../store/selectors";
import './SelectNetwork.scss';

const SelectNetwork = ({networks, networkSelected, setNetworkSelected}) => {
    const isDark = useAppSelector(getIsDarkTheme);

    const handleChange = (value) => {
        setNetworkSelected(value);
    };

    return (
        <div className={styles.selectNetwork}>
            <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={networkSelected.label}
                        sx={{
                            borderRadius: '11px',
                            borderBlock: '#FFFFFF80',
                            color: '#fff',
                            '.MuiSvgIcon-root ': isDark ? {
                                fill: "white !important",
                            } : null,
                        }}
                    >
                        {networks && networks.length > 0 && (
                            networks.map((item, index) => (
                                <MenuItem key={index} onClick={() => handleChange(item)} value={item.label}><span>{item.label}</span></MenuItem>
                            ))
                        )}
                    </Select>
                </FormControl>
            </Box>
        </div>
    )
}

export default SelectNetwork;