export const getPictureSrc = 'https://cbex.info/'

// account
export const getSaveSwap = (state) => state.account.saveSwap;
export const getLanguage = (state) => state.language.language;
export const getText = (state) => state.language.text;
export const getIsAuth = (state) => state.account.isAuth;
export const getIsDarkTheme = (state) => state.account.isDarkTheme;
export const getProfileInfo = (state) => state.account.profile;
export const getTotalBalance = (state) => state.account.totalBalance;
export const getEvent = (state) => state.account.event;

// main

export const getBlogs = (state) => state.main.blogs;
export const getKycItems = (state) => state.main.kycItems;
export const getAllTokens = (state) => state.main.tokens;
export const getAllPairs = (state) => state.main.spotPairs;
export const getStakingAllTokens = (state) => state.main.stakingTokens;
export const getOrderList = (state) => state.main.orderList;

// wallet

export const getBalances = (state) => state.wallet.balances;
export const getSortBalanceList = (state) => state.wallet.sortBalanceList;
export const getHistory = (state) => state.wallet.historyList;
export const getAllWallets = (state) => state.wallet.wallets;