import React, {useState} from "react";
import styles from './OperationsQuestions.module.scss';
import arrow from '../../../../assets/icons/arrowUpAccordion.svg';
import arrowDark from '../../../../assets/icons/arrowUpAccordionDark.svg';
import {useAppSelector} from "../../../../hooks/redux";
import {getIsDarkTheme} from "../../../../store/selectors";

const Question = ({quest, answer}) => {
    const [show, setShow] = useState(false);
    const isDarkTheme = useAppSelector(getIsDarkTheme);

    return (
        <div className={show ? `${styles.questionActive} question` : `${styles.question} question`}>
            <h4 onClick={() => setShow(!show)}>{quest}<img src={isDarkTheme ? arrow : arrowDark} alt="" /></h4>
            <p>{answer}</p>
        </div>
    )
}

export default Question;