import React, {useEffect, useState} from "react";
import styles from './SpotOrderForm.module.scss';
import SpotOrderFormHeader from "./SpotOrderFormHeader";
import SpotOrderFormBalances from "./SpotOrderFormBalances";
import CreditBox from "./CreditBox/CreditBox";
import SpotOperationsChangers from "./SpotOperationsChangers/SpotOperationsChangers";
import PriceInputBox from "../PriceInputBox/PriceInputBox";
import QuantityTotalBox from "./QuantityTotalBox/QuantityTotalBox";
import QuantityRange from "./QuantityRange/QuantityRange";
import {createOrder, getPairPrice, getUserBalances} from "../../../../../api/api";
import {Preloader} from "../../../../Common/Preloader/Preloader";
import {useAppDispatch} from "../../../../../hooks/redux";
import {updateOrderList} from "../../../../../store/main-slice";

const SpotOrderForm = ({pair, text}) => {
    const dispatch = useAppDispatch();
    const [operation, setOperation] = useState('Buy');
    const [priceChoose, setPriceChoose] = useState('Limit');
    const [balance, setBalance] = useState(null);
    const [usdtBalance, setUsdtBalance] = useState(null);
    const [process, setProcess] = useState(0);
    const [coursePrice, setCoursePrice] = useState('');
    const [quantity, setQuantity] = useState('');
    const [total, setTotal] = useState('');
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errorText, setErrorText] = useState(null);

    useEffect(() => {
        if (pair) {
            getPairPrice(pair.value).then((result) => {
                if (result && result.price) {
                    setCoursePrice(Number(result.price).toFixed(2));
                    setQuantity('');
                    setTotal('');
                    setProcess(0);
                }
            })
            getUserBalances(pair.base).then((response) => {
                if (response) {
                    setBalance(response)
                }

            })
            getUserBalances('USDT').then((response) => {
                if (response) {
                    setUsdtBalance(response.balance)
                }
            })
        }
    }, [pair])

    const send = (value) => {
        setIsLoading(true);
        if (pair?.value && coursePrice !== '') {
            if (priceChoose === 'Limit' && operation === 'Buy') {
                createOrder(pair.value, priceChoose.toLowerCase(), value, total, coursePrice).then((response) => {
                    if (response && response.value) {
                        dispatch(updateOrderList(response));
                        setIsLoading(false);
                    }
                });
            } else {
                createOrder(pair.value, priceChoose.toLowerCase(), value, quantity, coursePrice).then((response) => {
                    if (response && response.value) {
                        dispatch(updateOrderList(response))
                        setIsLoading(false);
                    }
                });
            }
        } else {
            setIsLoading(false);
        }
    }

    const onSubmit = (value) => {
        if (operation === 'Sell' && priceChoose === 'Limit' && quantity < pair.settings?.minLimitAmount) {
            setErrorText(`minimum limit amount ${pair.settings.minLimitAmount}`)
        } else if (operation === 'Sell' && priceChoose === 'Limit' && quantity > pair.settings?.maxLimitAmount) {
            setErrorText(`maximum limit amount ${pair.settings.minLimitAmount}`)
        } else if (operation === 'Sell' && priceChoose === 'Market' && quantity < pair.settings?.minMarketAmount) {
            setErrorText(`minimum market amount ${pair.settings.minMarketAmount}`)
        } else if (operation === 'Sell' && priceChoose === 'Market' && quantity > pair.settings?.maxMarketAmount) {
            setErrorText(`maximum market amount ${pair.settings.minMarketAmount}`)
        } else if (operation === 'Buy' && priceChoose === 'Limit' && total < pair.settings?.minLimitAmount) {
            setErrorText(`minimum limit amount ${pair.settings.minMarketAmount}`)
        } else if (operation === 'Buy' && priceChoose === 'Limit' && total > pair.settings?.maxLimitAmount) {
            setErrorText(`maximum limit amount ${pair.settings.minMarketAmount}`)
        } else {
            setErrorText(null);
            send(value)
        }
    }

    console.log(balance)


    return (
        <div className={`${styles.spotOrderForm} spotBg spot spotOrderForm`}>
            {/*<SpotOrderFormHeader text={text}/>*/}
            {balance && usdtBalance && (
                <SpotOrderFormBalances coinBalance={balance.balance} usdtBalance={usdtBalance}
                                       operation={operation} coin={balance.coin} isError={isError} text={text}/>
            )}
            <CreditBox/>
            <SpotOperationsChangers operation={operation}
                                    setOperation={setOperation}
                                    priceChoose={priceChoose}
                                    setPriceChoose={setPriceChoose}
                                    setQuantity={setQuantity}
                                    setTotal={setTotal}
                                    setProcess={setProcess}
            />
            {priceChoose === 'Limit' && (
                <PriceInputBox priceChoose={priceChoose}
                               coursePrice={coursePrice} quantity={quantity}
                               setCoursePrice={setCoursePrice} operation={operation}
                               setTotal={setTotal} text={text}/>
            )}
            {balance && (
                <QuantityTotalBox quantity={quantity} priceChoose={priceChoose} setQuantity={setQuantity} total={total}
                                  setTotal={setTotal} operation={operation} coursePrice={coursePrice}
                                  quantityToken={operation === 'Buy' ? pair.quote : pair.base}
                                  totalToken={operation === 'Buy' ? pair.base : pair.quote} setProcess={setProcess}
                                  balance={operation === 'Buy' ? usdtBalance : balance.balance} isError={isError}
                                  setIsError={setIsError} text={text} settings={pair.settings}
                />
            )}
            {errorText && (
                <span className={styles.errorText}>{errorText}</span>
            )}
            {balance && balance.balance > 0 && coursePrice && usdtBalance && (
                <QuantityRange process={process} setProcess={setProcess} setQuantity={setQuantity}
                               coursePrice={coursePrice}
                               balance={operation === 'Buy' ? usdtBalance : balance.balance}
                               operation={operation} setTotal={setTotal}/>
            )}
            <div className={styles.action}>
                {operation === 'Buy' ? (
                    <button className={styles.action__buy} onClick={() => onSubmit('buy')}
                            disabled={quantity === '' || Number(quantity) === 0 || isError}>
                        <span>Buy</span>
                    </button>
                ) : (
                    <button className={styles.action__sell} onClick={() => onSubmit('sell')}
                            disabled={quantity === '' || Number(quantity) === 0 || isError}>
                        <span>Sell</span>
                    </button>
                )}
            </div>
            {isLoading && (
                <div className='loadingLocal'>
                    <Preloader/>
                </div>
            )}

        </div>
    )
}

export default SpotOrderForm;