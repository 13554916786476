import React, {useEffect, useState} from "react";
import styles from './SwapLeftBox.module.scss';
import FieldSelect from "../../../../Common/FieldSelect/FieldSelect";
import {Preloader} from "../../../../Common/Preloader/Preloader";
import {useAppDispatch, useAppSelector} from "../../../../../hooks/redux";
import {getProfileInfo, getSaveSwap} from "../../../../../store/selectors";
import {setEvent, setSaveSwap} from "../../../../../store/account-slice";
import {sendSwap} from "../../../../../api/api";
import NotVerify from "../../../../Common/NotVerify/NotVerify";

let priceSocket = null;

const SwapLeftBox = ({swap, tokenList, tokenFrom, setTokenFrom, settings, balance}) => {
    const dispatch = useAppDispatch();
    const profile = useAppSelector(getProfileInfo);
    const [isLoading, setIsLoading] = useState(false);
    const [listFrom, setListFrom] = useState(null);
    const [listTo, setListTo] = useState(null);
    const [tokenTo, setTokenTo] = useState(tokenList[5]);
    const [amountFrom, setAmountFrom] = useState('');
    const [amountTo, setAmountTo] = useState('');
    const [price, setPrice] = useState(null);
    const [showVerify, setShowVerify] = useState(false);


    useEffect(() => {
        setListTo(tokenList.filter(item => item.label !== tokenFrom.label));
        setAmountFrom('');
    }, [tokenFrom])

    useEffect(() => {
        // setListFrom(tokenList.filter(item => item.label !== tokenTo.label));
        setListFrom(tokenList);
    }, [tokenTo])

    useEffect(() => {
        return () => {
            priceSocket.close()
            dispatch(setSaveSwap(0));
            priceSocket = null;
        }
    }, [])

    useEffect(() => {
        if (priceSocket === null) {
            priceSocket = new WebSocket('wss://cbex.info/ws/pairs');
            priceSocket.onmessage = function (e) {
                if (price !== JSON.parse(e.data).price) {
                    setPrice(JSON.parse(e.data).price);
                }
            }
        }
    }, [])

    useEffect(() => {
        if (tokenFrom && tokenTo) {
            setIsLoading(true);
            priceSocket.close();
            priceSocket = new WebSocket('wss://cbex.info/ws/pairs');
            priceSocket.onmessage = function (e) {
                if (price !== JSON.parse(e.data).price) {
                    setPrice(JSON.parse(e.data).price);
                    setIsLoading(false);

                }
            }
            priceSocket.onopen = () => {
                priceSocket.send(`${tokenFrom.label}${tokenTo.label}`);
            }
        }
    }, [tokenFrom, tokenTo])


    const onSwap = () => {
        setShowVerify(false);
        setIsLoading(true);
        if (tokenFrom && tokenTo && amountFrom > 0) {
            sendSwap(tokenFrom.label, tokenTo.label, amountFrom).then((res) => {
                if (res && res.status) {
                    dispatch(setEvent(`${res.status} ${res.received}`));
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            })
        }
    }


    return (
        <div className={styles.swapLeftBox}>
            <div className={styles.swapFields}>
                {!price && (
                    <div className='loadingLocal' style={{position: 'fixed'}}>
                        <Preloader/>
                    </div>
                )}
                {listFrom && tokenFrom && (
                    <FieldSelect label={swap.spend}
                                 placeholder={balance?.balance > 0 ? `${settings?.settings?.minSwapAmount}-${balance?.balance - settings?.commissions[1].commission}` : 0}
                                 selectList={listFrom} value={tokenFrom}
                                 setValue={setTokenFrom} amount={amountFrom} setAmount={setAmountFrom} from
                                 setVersusField={setAmountTo} price={price} fromLabel={tokenFrom.label}
                                 amountFrom={amountFrom}/>
                )}
                {listTo && tokenFrom && (
                    <FieldSelect label={swap.receive} placeholder={swap.enterAmount}
                                 selectList={listTo} value={tokenTo} setValue={setTokenTo} amount={amountTo}
                                 setAmount={setAmountTo} setVersusField={setAmountFrom} price={price}
                                 fromLabel={tokenFrom.label} amountFrom={amountFrom}/>
                )}
            </div>
            {price && tokenFrom.label !== 'USDT' && (
                <p className={styles.value}>1 {tokenFrom.label} ≈ {(1 * price)} {tokenTo.label}</p>
            )}
            {price && tokenFrom.label === 'USDT' && (
                <p className={styles.value}>1 {tokenTo.label} ≈ {(1 * price)} {tokenFrom.label}</p>
            )}
            {showVerify && (
                <div className={styles.wrapper}>
                    <NotVerify light
                               text={`Your actions are limited! trading and other operations are not available, for full access please`}/>
                </div>
            )}
            <div className={styles.actions}>
                <button className={'blueBtn'} type='button'
                        onClick={() => profile?.kycVerified ? onSwap() : setShowVerify(true)}
                        disabled={amountFrom === '' || Number(amountFrom) === 0}>
                    <span>{swap.pay}</span>
                </button>
            </div>
            {isLoading && (
                <div className='loadingLocal' style={{position: 'fixed'}}>
                    <Preloader/>
                </div>
            )}
        </div>
    )
}

export default SwapLeftBox;