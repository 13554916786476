import React, {useState} from "react";
import styles from './VerificationKYC.module.scss';
import {Swiper, SwiperSlide} from 'swiper/react';
import ItemKyc from "./ItemKyc";
import {useAppSelector} from "../../../../hooks/redux";
import {getKycItems} from "../../../../store/selectors";
import {Preloader} from "../../../Common/Preloader/Preloader";

const VerificationKYC = ({text}) => {
    const items = useAppSelector(getKycItems);
    const [isLoading, setIsLoading] = useState(false);

    return (
        <div className={`${styles.verificationKYC} verificationKYC`}>
            <div className={styles.verificationKYC__header}>
                {window.innerWidth > 1023 && (
                    <h2>{text.KYCVerification}</h2>
                )}
                {window.innerWidth < 1024 && (
                    <p className={styles.verificationKYC__subTitle}>Select a suitable benefit according to your needs
                        and complete the verification
                    </p>
                )}
            </div>
            <div className={styles.verificationKYC__content}>
                <Swiper
                    spaceBetween={15}
                    slidesPerView={window.innerWidth > 1023 ? `3` : window.innerWidth < 1024 && window.innerWidth > 743 ? '2.2' : '1.2'}
                >
                    {items && items.length > 0 && (
                        items.map((item, index) => (
                            <SwiperSlide key={index}>
                                <ItemKyc item={item} setIsLoading={setIsLoading}/>
                            </SwiperSlide>
                        ))
                    )}
                </Swiper>
            </div>
            {isLoading && (
                <div className='loadingLocal' style={{position: 'fixed'}}>
                    <Preloader/>
                </div>
            )}
        </div>
    )
}

export default VerificationKYC;