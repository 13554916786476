import React from "react";
import styles from '../SpotTotalChanger.module.scss';

const SpotHeaderToken = ({text, pair, pictureSrc}) => {
    return (
        <div className={styles.spotHeaderToken}>
            <img className={styles.coinIcon} src={`${pictureSrc}${pair.baseIconPath}`} alt=""/>
            <div className={styles.coinInfo}>
                <h6>{pair.base}<span>/{pair.quote}</span></h6>
                <p><span>{pair.baseName}</span> {text.price}</p>
            </div>
        </div>
    )
}

export default SpotHeaderToken;