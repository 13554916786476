import React from "react";
import styles from "./TradingTable.module.scss";


const TradingTokenItem = ({item}) => {
    return (
        <div className={styles.content__row}>
            <div className={`${styles.content__point} ${styles.tokenName}`}>
                <p><img src={item.icon} alt=""/> {item.fullName} <span>({item.token})</span></p>
            </div>
            <div className={`${styles.content__point} ${styles.tokenPrice}`}>
                {window.innerWidth < 768 && (
                    <span className={styles.content__label}>Price</span>
                )}
                <p>$ {item.price}</p>
            </div>
            <div className={`${styles.content__point} ${styles.tokenPrice}`}>
                {window.innerWidth < 768 && (
                    <span className={styles.content__label}>24h change</span>
                )}
                <p style={item.isUp ? {color: '#7AD1A5'} : {color: '#BA5349'}}>{item.percentage}%</p>
            </div>
            <div className={`${styles.content__point} ${styles.tokenActions}`}>
                <button>
                    <span>Trade</span>
                </button>
            </div>
        </div>
    )
}

export default TradingTokenItem;