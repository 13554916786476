import React, {useEffect, useState} from "react";
import styles from './DepositLeftBox.module.scss';
import TokenChanger from "../../../../Common/TokenChanger/TokenChanger";
import '../../../../Common/TokenChanger/TokenChanger.scss';
import SelectNetwork from "../../../../Common/SelectNetwork/SelectNetwork";
import SubNotification from "../../../../Common/SubNotification/SubNotification";
import {useAppSelector} from "../../../../../hooks/redux";
import {getAllTokens, getProfileInfo} from "../../../../../store/selectors";
import {Preloader} from "../../../../Common/Preloader/Preloader";
import {createAddress} from "../../../../../api/api";
import {useParams} from "react-router-dom";
import NotVerify from "../../../../Common/NotVerify/NotVerify";

const DepositLeftBox = ({deposit, setWallet, setTokenInfo, setIsLoading, token, setToken, onClose}) => {
    const tokenList = useAppSelector(getAllTokens);
    const profile = useAppSelector(getProfileInfo);
    const {coin} = useParams();
    const [networks, setNetworks] = useState(null);
    const [networkSelected, setNetworkSelected] = useState(null);
    const [showVerify, setShowVerify] = useState(false);

    useEffect(() => {
        if (tokenList && tokenList.length > 0 && coin) {
            const currentCoin = tokenList.find((item) => item.label === coin);
            setToken(currentCoin)
        } else if (tokenList && tokenList.length > 0 && !coin) {
            setToken(tokenList[0])
        }
    }, [tokenList])

    useEffect(() => {
        if (token && networks !== token.networks) {
            setNetworks(token.networks);
        }
    }, [token])

    useEffect(() => {
        if (networks && networks.length > 0) {
            setNetworkSelected(networks[0]);
        }
    }, [networks]);

    useEffect(() => {
        onClose()
    }, [networkSelected])

    const onSubmit = () => {
        setShowVerify(false);
        setIsLoading(true);
        createAddress(networkSelected.value).then((response) => {
            if (response && response.status === 'success') {
                setTokenInfo({token: token.fullname, network: networkSelected.label});
                setWallet(response);
                setIsLoading(false);
            } else {
                setTimeout(() => {
                    setIsLoading(false);
                }, 500)
            }
        })
    }

    return (
        <div className={styles.depositLeftBox}>
            <h4>{deposit.selectCoin}</h4>
            <p className={styles.subtitle}>{deposit.SelectCryptocurrency}</p>
            <div className={`${styles.tokenSelect} selectFieldBox`}>
                {token
                    ? <TokenChanger tokenList={tokenList} token={token} setToken={setToken}/>
                    : <div className='loadingLocal' style={{backgroundColor: 'transparent'}}>
                        <Preloader/>
                    </div>
                }
            </div>
            <h4>{deposit.selectNetwork}</h4>
            <p className={styles.subtitle}>{deposit.makeNetwork}</p>
            <div className='selectFieldBox'>
                {networkSelected
                    ? <SelectNetwork networks={networks} networkSelected={networkSelected} setNetworkSelected={setNetworkSelected} />
                    : <div className='loadingLocal' style={{backgroundColor: 'transparent'}}>
                        <Preloader/>
                    </div>
                }
            </div>
            {showVerify &&  (
                <div className={styles.wrapper}>
                    <NotVerify light text={`Your actions are limited! trading and other operations are not available, for full access please`}/>
                </div>
            )}
            <div className={styles.networkActions}>
                <button type={'button'} disabled={!token || !networkSelected} className={`${styles.networkActions__show} blueBtn`}
                        onClick={() => profile?.kycVerified ? onSubmit() : setShowVerify(true)} >
                    <span>{deposit.showAddress}</span>
                </button>
            </div>
            <SubNotification text={deposit.withdrawNotification} />
        </div>
    )
}

export default DepositLeftBox;